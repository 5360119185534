.outline-gsoc {
    /* display: flex; */
    justify-content: center;
    align-items: center;
    /* flex-direction: column; */
    margin-top: 3%;
    padding: 4% 8% 5% 8%;
    border: 2px solid white;
    border-radius: 4rem;
    margin-left: 8%;
    margin-right: 8%;

}

.gsoc-heading {
    font-size: 2rem;
    font-weight: 600;
    color: white;
    margin-bottom: 2%;
    text-align: center;
}

.gsoc-img img {
    width: 100%;
    border-radius: 2rem;
}

.gsoc-desktop-vdo video {
    width: 100%;
    border-radius: 1rem;

}

.gsoc-desktop-vdo-mobile video {
    width: 25%;
    border-radius: 1rem;

}

.gsoc-desktop-vdo-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2%;
}

.hr-break {
    margin-top: 5%;
    margin-bottom: 3%;
    width: 80%;
    opacity: .3;
}


.Project-sections {
    margin-top: 3%;
}


.gsoc-intro-heads {
    justify-content: flex-start;
    display: flex;
    margin-top: 1.5%;
}

.head-heading-gsoc {
    font-size: 1.7rem;
}

.head-sub-head-link-gsoc a {
    font-size: 1.7rem;
    /* text-decoration: underline; */
    color: rgb(179, 0, 196);
}

.section--gsoc-heading {
    font-size: 1.9rem;
    font-weight: 800;
    color: white;
    margin-bottom: 2%;
}

.link-gsoc-color {
    color: rgb(179, 0, 196);
}

table {
    border: 2px solid rgb(187, 0, 255);
    padding: 1.4rem;
    border-radius: 2rem;
}



td {
    padding: 0.5rem;
    /* border: 0.1px solid rgb(209, 190, 255); */
    border-radius: 0.8rem;
    /* margin: 1rem; */

}


.color-link {
    color: rgb(0, 136, 255);
}

.color-summary {
    color: rgb(225, 127, 255);
}

.color-password {
    color: rgb(255, 178, 127);
}

.section-sub-text-gsoc {
    font-size: 1.05rem;
}

.section--gsoc-heading-2 {
    font-size: 1.4rem;
    font-weight: 800;
    color: rgb(212, 232, 255);
    margin-bottom: 2%;
}


/* 535 */

@media (max-width: 575px) {

    .gsoc-heading {
        font-size: 1.5rem;
        font-weight: 600;
        color: white;
        margin-bottom: 2%;
        text-align: center;
    }


    .outline-gsoc {
        /* display: flex; */
        justify-content: center;
        align-items: center;
        /* flex-direction: column; */
        margin-top: 3%;
        padding: 4% 8% 5% 8%;
        border: 2px solid white;
        border-radius: 4rem;
        margin-left: 2%;
        margin-right: 1%;
    }

}

@media (max-width: 535px) {

    table {
        border: 2px solid rgb(187, 0, 255);
        padding: 1%;
        border-radius: 1rem;
    }



    td {
        padding: 0.2rem;
        /* border: 0.1px solid rgb(209, 190, 255); */
        border-radius: 0.8rem;
        /* margin: 1rem; */
        font-size: 0.7rem;
    }



    .head-heading-gsoc {
        font-size: 1.5rem;
    }

    .head-sub-head-link-gsoc a {
        font-size: 1.5rem;
    }

    .section-sub-text-gsoc {
        font-size: 1.05rem;
        text-align: justify;
    }

    .Project-sections p {
        text-align: justify;

    }

    .text-justify {
        text-align: justify;
    }

}

/* 535 */


@media (max-width: 344px) {





    td {
        padding: 0.2rem;
        /* border: 0.1px solid rgb(209, 190, 255); */
        border-radius: 0.8rem;
        /* margin: 1rem; */
        font-size: 0.5rem;
    }

}