@import url('https://fonts.googleapis.com/css2?family=Caveat&family=Titillium+Web:wght@600&display=swap');


* {
    color: white;
    font-family: 'Titillium Web', sans-serif;
    scroll-behavior: smooth;
}

.homepage-main {
    /* position: relative; */
    /* Added positioning */
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1.3rem;
    height: 19rem;
    border-radius: 4rem;
    border: 2px solid #debbbb;
    background-color: #d0d0fa;

}

.hp-left {

    /* position: absolute; */
    /* Added positioning */
    left: 0;
    /* Align to the left */
    padding-left: 1rem;
    /* Add some padding for visibility */
    background-color: rgba(255, 255, 255, 0);
    /* margin: 0.7rem 2rem; */
    /* padding: 0.7rem 10rem 0.7rem 2rem; */
}

.hp-right {
    position: absolute;
    /* Added positioning */
    right: 0;
    /* Align to the right */
    padding-left: 1rem;
    background-color: rgba(255, 255, 255, 0);
    /* Add some padding for visibility */
}

.hs2 {
    /* position: absolute; */
    /* Added positioning */
    right: 0;
    /* Align to the right */
    padding-left: 1rem;
    background-color: rgba(255, 255, 255, 0);
    font-size: 1.5rem;
    padding-right: 2rem;
    /* Add some padding for visibility */


    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

}

.ashish-txt {
    background-color: rgba(240, 248, 255, 0);
    color: black;
    /* font-size: 7rem; */
    /* font-family: 'Caveat', cursive; */
    flex: content;
    font-size: 6rem;
    font-family: 'Titillium Web', sans-serif;
    padding-left: 2rem;

}

h2 {
    background-color: rgba(240, 248, 255, 0);
    color: black;
}

/* My Image! */

.img-round {
    border-radius: 50%;
    width: 16rem;
    height: 16rem;
    margin-right: 4rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 2rem;
}

/* Section 2 on HomePage */
.homeSection2 {
    /* position: relative; */
    /* Added positioning */
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 4.3rem;
    margin-left: 3rem;
    height: 5rem;
    border-radius: 4rem;
    /* border: 2px solid #debbbb; */
    /* background-color: #d0d0fa; */

}

/* Rounded btn */

.nav3 {
    justify-content: flex-start;
    display: flex;
    /* border-style: double; */
}

.nav-btn3 {
    margin: 0.2rem 0.3rem;
    padding: 0.1rem 1rem 0.1rem 1rem;
    fill: aliceblue;
    border-radius: 7rem;
    border: 2px solid #debbbb;
    /* background-color: #a21111; */
    cursor: default;
    display: inline;
    /* border-style: double; */
    justify-content: flex-start;
    display: flex;
    font-size: 0.6rem;

}

.nav-btn6 {
    margin: 0.2rem 0.2rem;
    padding: 0rem 8rem 0.1rem 3rem;
    fill: rgb(0, 0, 0);
    border-radius: 7rem;
    border: 2px solid #debbbb;
    background-color: #ffffff;
    /* cursor: default; */
    display: inline;
    /* border-style: double; */
    justify-content: flex-start;
    display: flex;
    font-size: 0.7rem;

}

.btn-on-card-dgn {
    cursor: pointer;
    width: 20%;
}


.nav-btn3:hover {
    background-color: #ffffff;
    cursor: default;
    border-style: double;
    border: 2px solid #4d0c0c;
    color: black !;



}

.nav-btn6:hover {
    background-color: #000000;
    /* cursor: default; */
    border-style: double;
    border: 2px solid #4d0c0c;
    color: rgb(255, 255, 255) !;



}

.nav-btn-link3 {

    color: rgb(94, 98, 99);
    font-size: 1.3em;
    background-color: #ffffff00;
    text-decoration: none;
    /* transparent h */

}

.nav-btn-link6 {

    color: rgb(94, 98, 99);
    font-size: 1.7em;
    background-color: #ffffff00;
    text-decoration: none;
    /* transparent h */

}

.nav-btn-link3:hover {


    background-color: #181d2100;
    color: rgb(0, 0, 0);
    /* font-size: 1.3em; */


}

.nav-btn-link6:hover {


    background-color: #d9d9d900;
    color: rgb(237, 237, 237);
    /* font-size: 1.3em; */


}


/* HomePage2 */
/* HomePage2 */
/* HomePage2 */
/* HomePage2 */
/* HomePage2 */
/* HomePage2 */
/* HomePage2 */
/* HomePage2 */

.HomePage2 {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    margin-top: 1%;
    margin-left: 3%;
    min-height: 6rem;
    border-radius: 4rem;
    /* border: 2px solid #debbbb; */
    /* background-color: #d0d0fa; */
}

.hp2-card1 {
    /* display: flex; */
    /* flex-direction: row; */
    /* align-items: center; */

    margin-top: 3%;
    margin-left: 3%;
    min-height: 20rem;
    min-width: 27rem;
    border-radius: 1.4rem;
    border: 2px solid #ebdede;
    background-color: #d0d0fa00;
}



/* Card1 */
.hp2-card1-img {

    border-radius: 1.4rem;
    margin: 4%;
    height: 12rem;
    width: 32rem;
    /* margin-right: 4rem; */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

}

.card1-text {

    margin: 1rem;
    margin-left: 5%;

}

.card1-text-heading {
    font-size: 1.3rem;
    font-family: 'Titillium Web', sans-serif;
    font-weight: 800;
    margin-bottom: 1rem;
}

/* Card2 */
/* Card2 */
/* Card2 */



.hp2-card2 {
    /* display: flex; */
    /* flex-direction: row; */
    /* align-items: center; */

    margin-top: 3%;
    margin-left: 8%;
    height: 22rem;
    min-width: 27rem;
    border-radius: 1.4rem;
    border: 2px solid #ebdede;
    background-color: #eae2a0;
}

.card2 {
    background-color: #eae2a0;
    ;
}

.hp2-card2-img {

    border-radius: 1.4rem;
    margin: 4%;
    height: 12rem;
    width: 32rem;
    /* margin-right: 4rem; */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #eae2a0;

}

.card2-text {

    margin: 1rem;
    margin-left: 5%;
    background-color: #eae2a0;

    max-width: 40rem;
}

.card2-text-heading {
    font-family: 'Titillium Web', sans-serif;
    margin-bottom: 1rem;
    background-color: #eae2a0;
    color: black;
}

.card2-text-heading p {
    background-color: #ffffff00;
    font-weight: bold;
    font-size: 1.2rem;
    color: black;

}

.card2-text-content {
    background-color: #eae2a0;
    color: black;
}

.card2-text-content a {
    background-color: #eae2a0;
    color: black;

}

.card2-img {
    background-color: #eae2a0;
}


/* Card 3 */

.marign-adjust {
    margin-top: 13%;
    background-color: #b1dbb4;
    background-color: #ecc0ef;
    /* margin-bottom: 13%; */
}

.three-bg {
    background-color: #ecc0ef;
    background-color: #ffc0c0;

}

.three-bg-a a {
    background-color: #ecc0ef;
    background-color: #ffc0c0;

}



/* HOmePage3 */
/* HOmePage3 */
/* HOmePage3 */
/* HOmePage3 */
/* HOmePage3 */
/* HOmePage3 */

.homepage3 {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 2%;

}



.homepage3-heading {
    margin-top: 11%;
    margin-left: 4.3%;
    /* margin-top: 3%; */
    font-size: 3rem;
    font-family: 'Titillium Web', sans-serif;
    font-weight: 800;
    margin-bottom: 1rem;
    /* color: black; */
}



/* card */

.hp-projects {
    margin-top: 16%;
    margin-left: 4%;
    /* width: 23rem; */
    height: 20rem;
}

.hp3-card {
    border-radius: 1.3rem;
    margin: 1.7rem 1rem;
    border: 3px wheat;

    padding: 0.2rem 2rem 0rem 1rem;
}

.hp3-bg-white {
    border-radius: 1.3rem;
    color: black;
    background-color: #9affcd;

}

.hp3-bg-white a {
    color: black;
    background-color: #9affcd;

}

.hp3-card-heading {
    margin-top: -3%;
    font-size: 1.3rem;
    font-weight: 700;
    margin-bottom: 0.3rem;
}

.hp3-card-content {
    margin-top: 3%;
    font-size: 1.1rem;
    margin-bottom: 0.3rem;
    vertical-align: bottom;
}

.hp3-card-link {
    margin-top: 8%;
    font-size: 1.1rem;
    margin-bottom: 0.3rem;
    vertical-align: bottom;
    color: #a21111;
    font-weight: 700;
}


.hp3-bg-yellow {
    background-color: #eae3a000;
    background-color: #fffa62;
    color: black;
    border-radius: 2rem;
}

.hp3-bg-yellow a {
    background-color: #eae3a000;
    background-color: #fffa62;
    color: black;
}

.hp3-bg-blue {
    background-color: #eae3a000;
    background-color: #ef51ff;
    color: black;
    border-radius: 2rem;
}

.hp3-bg-blue a {
    background-color: #eae3a000;
    background-color: #ef51ff;

    color: black;
}

.hp3-bg-green {
    background-color: #eae3a000;
    background-color: #b2ff63;
    color: black;
    border-radius: 2rem;
}

.hp3-bg-green a {
    background-color: #eae3a000;
    background-color: #b2ff63;

    color: black;
}



/* Card skill btn */
.card-skill-div-res {
    display: flex;
    background-color: #ffffff00;
    margin-top: 3%;

}

.card-btn-skill {
    background-color: #ffffff00;
    padding: 0rem 0.5rem 0rem 0.5rem;
}

.card-skill {
    margin-left: 0.4rem;
    margin-right: 0.4rem;
    border-radius: 4rem;
    border: 1px solid rgb(1, 0, 0);
    background-color: #9c9999a7;
}

.card-skill:hover {

    background-color: #89cf9469;
    cursor: default;
}

.card-btn-skill-txt {
    font-size: 0.5rem;
    background-color: #ffffff00;
    color: rgb(0, 0, 0);

}



/* Link text  */
.hp3-card-link a {
    font-size: 1.2rem;
    color: #585858;
}

.hp3-card-link a:hover {
    color: #000000;

}


/* Homepage 4 */
/* Homepage 4 */
/* Homepage 4 */
/* Homepage 4 */

.hp4 {
    margin-left: 5%;
    margin-bottom: 4%;
    display: flex;

}

.hp4Form {
    margin-bottom: 4%;
    margin-top: 4%;
    border-radius: 1.4rem;
    /* border: 6px solid wheat; */
    border-radius: 4rem;
    min-width: 40rem;
    min-height: 35rem;
    max-width: 40%;
    max-height: 20rem;
    position: relative;
}

.hp4Form::before,
.hp4Form::after {
    content: "";
    position: absolute;
    background-color: wheat;
}

.hp4Form::before {
    top: -6px;
    left: -6px;
    width: 20px;
    height: calc(100% + 12px);
    transform: skewY(-45deg);
}

.hp4Form::after {
    bottom: -6px;
    right: -6px;
    width: 20px;
    height: calc(100% + 12px);
    transform: skewY(-45deg);
}

.form-bg {
    background-color: #eae3a000;
    color: #4d0c0c;
    font-size: 2rem;



}

.form-heading {
    margin-top: 4%;
    margin-left: 14%;
    font-size: 2rem;
    font-family: 'Titillium Web', sans-serif;
    font-weight: 800;
    /* margin-bottom: 1rem; */
    color: #ff0000;
}

.transparent-input {
    border: none;
    border-bottom: 1px solid rgb(255, 0, 0);
    background-color: transparent;
    outline: none;
    font-size: 1.5rem;
    width: 70%;
    padding: 1rem;
    margin-bottom: 1rem;
    /* color: black; */
}

.transparent-input-textarea {
    /* border: none; */
    border: 1px solid rgb(240, 0, 0);
    background-color: transparent;
    outline: none;
    font-size: 1.5rem;
    max-width: 70%;
    min-height: 20%;
    padding: 1rem;
    margin-bottom: 1rem;
    /* color: black; */
    max-height: 10rem;
    min-height: 3rem;
    min-width: 10rem;
}

.form-inputs-center {
    margin-left: 8%;
    margin-top: 2%;
    display: flex;
    flex-direction: column;

}

/* Form-btn  */

.form-button-sbmt {
    margin-top: 1.5rem;
    margin-left: 25%;
    border: none;
    border-radius: 1.4rem;
    background-color: #7b0f05;
    color: white;
    font-size: 1.5rem;
    padding: 0.5rem 1.9rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    outline: none;
    font-family: 'Titillium Web', sans-serif;
    font-weight: 800;
    /* padding-right: 8rem; */
}

.form-button-sbmt:hover {
    background-color: #000000;
}

.error {
    color: red;
    font-size: 1.2rem;
    margin-left: 8%;
}

.error-message {
    color: red;
    font-size: 1.2rem;
    margin-left: 8%;
}



/* Find me at */

.contact-text {
    margin-top: 8%;
    margin-left: 14%;
    font-size: 2rem;
    font-family: 'Titillium Web', sans-serif;
    font-weight: 800;
    /* margin-bottom: 1rem; */
    color: #7ff2b9;
}

.contact-text-heading {
    margin-top: 4%;
    /* margin-left: 14%; */
    font-size: 2rem;
    font-family: 'Titillium Web', sans-serif;
    font-weight: 800;
    /* margin-bottom: 1rem; */
    color: #cbcbcb;
    ;


}

.contact-text-content-1-heading {
    margin-top: 4%;
    margin-left: 15%;
    font-size: 2rem;
    font-family: 'Titillium Web', sans-serif;
    font-weight: 800;
    /* margin-bottom: 1rem; */
    color: #797979;
}


.mail-icon {
    /* padding-top: 1rem; */
    /* padding-right: 2rem; */

}

.contact-text-content-1-content {
    margin-top: 4%;
    /* margin-left: 14%; */
    font-size: 2rem;
    font-family: 'Titillium Web', sans-serif;
    font-weight: 800;
    /* margin-bottom: 1rem; */
    color: #797979;
    text-decoration: none;
}


/* Social Icon */

.social-icons {
    margin-top: 4%;
    font-size: 2rem;
    margin-left: 1.3rem;
    font-family: 'Titillium Web', sans-serif;
    font-weight: 800;
    /* margin-bottom: 1rem; */
    color: #ff0000;
    text-decoration: none;
    padding: 2rem;
}

.social-icons a {
    padding: 1.3rem;
    font-size: 2.5rem;
}

.contact-text-content {
    margin-top: 15%;
}


.homes-div-res {
    display: flex;
    justify-content: center;
}


.firstTwo {
    display: flex;
}

/* homepage btn */




.glow-on-hover {
    width: 220px;
    height: 50px;
    border: none;
    outline: none;
    color: #fff;
    background: #111;
    cursor: default;
    position: relative;
    z-index: 0;
    border-radius: 10px;
}

.glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.glow-on-hover:active {
    color: #000
}

.glow-on-hover:active:after {
    background: transparent;
}

.glow-on-hover:hover:before {
    opacity: 1;
}

.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 400% 0;
    }

    100% {
        background-position: 0 0;
    }
}

.hide-mobile {
    background: transparent;
    margin-left: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-text-content-1-content {
    font-size: 1.4rem;
    margin-left: 7.1px;
}

.contact-text-content-1 {
    /* justify-content: center; */
    display: flex;
    flex-direction: row;
    align-items: flex-end;

}

.contact-text-content {
    margin-top: 10%;
    margin-left: 1rem;
}

/* Responsivesness */
/* Responsivesness */
/* Responsivesness */
/* Responsivesness */
/* Responsivesness */
/* Responsivesness */
/* Responsivesness */
/* Responsivesness */
/* Responsivesness */
/* Responsivesness */
/* Responsivesness */
/* Responsivesness */
/* Responsivesness */
/* Responsivesness */
/* Responsivesness */
/* Responsivesness */
/* Responsivesness */
/* Responsivesness */
/* Responsivesness */
/* Responsivesness */
/* Responsivesness */
/* Responsivesness */
/* Responsivesness */


@media (max-width: 1409px) {

    .hp-projects {
        height: 22rem;
    }
}





@media (max-width: 1380px) {

    .hp-text-text {
        font-size: 1.2rem;
    }

    .HomePage2 {
        margin-left: 0rem
    }

    .contact-text-content-1-content {
        font-size: 1.3rem;
    }
}

@media (max-width: 1282px) {

    .hp2-card1-img {
        width: 27rem;
    }
}

@media (max-width: 1281px) {

    .nav-btn {
        padding: 0.3rem 3rem 0.3rem 1rem
    }
}

@media (max-width: 1218px) {

    .hp-text-text {
        font-size: 1rem;
    }

    .homepage-main {
        margin-left: 3%;
        margin-right: 3%;
    }

    * {
        .hp4Form {
            min-width: 2rem;
        }
    }

}

@media (max-width: 1136px) {

    .nav-btn {
        padding: 0.3rem 3rem 0.3rem 1rem
    }

    .mrgn-nav {
        margin-left: 6%
    }
}

@media (max-width: 1097px) {

    .hp-text-text {
        font-size: 0.8rem;
    }

    .hp2-card1-img {
        width: 25rem;
        height: 10rem;
    }
}

@media (max-width: 1097px) {

    .hp2-card2 {
        margin-left: 3%;
        height: 24rem;
        /* min-width: 2rem; */
    }
}



@media (max-width: 1040px) {
    .hp3-card-heading {
        font-size: 1rem;
    }

    .hp3-card-content {
        font-size: 0.9rem;
    }

}

@media (max-width: 988px) {

    .nav-btn-link2 {
        display: none;
    }

    .hp2-card1 {
        min-width: 2rem;
    }

    .homeSection2 {
        font-size: 0.8rem;
        display: block;
    }

    .HomePage2 {
        margin-left: 0rem
    }

    .hp-right {
        position: relative;
        margin-top: 2%;
    }

    .img-correction {
        position: absolute;
    }


    .form-heading {
        margin-top: 4%;
        margin-left: 14%;
        font-size: 1.6rem
    }

    .transparent-input {
        font-size: 1rem;
    }

    .transparent-input-textarea {
        font-size: 1rem;
    }

    .form-button-sbmt {
        margin-top: 0rem;
        margin-left: 0%;
        border: none;
        border-radius: 1.4rem;
        background-color: #7b0f05;
        color: white;
        font-size: 1.5rem;
        padding: 0.5rem 1.9rem;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        outline: none;
        font-family: 'Titillium Web', sans-serif;
        font-weight: 800;
        /* padding-right: 8rem; */
    }

    .hp-text-text {
        font-size: 1.1rem;
    }
}

@media (max-width: 930px) {
    .hp2-card1-img {
        width: 23rem;
        height: 9rem;
    }
}

@media (max-width: 910px) {
    .nav-btn {
        padding: 0.3rem 1rem 0.3rem 1rem;
    }

    .img-round {
        width: 10rem;
        height: 10rem;
    }

    .ashish-txt {
        font-size: 5rem;

    }

    .nav-btn-link {
        font-size: 1.1rem;
    }


    .hp2-card1-img {
        width: 19rem;
        height: 8rem;
    }
}

@media (max-width: 883px) {

    .small-lnk {
        font-size: 1.8rem;

    }

    .hp2-card2 {
        margin-left: 1%;
        min-width: 2rem;
    }

    .card2-text {
        margin: 0rem;
        margin-left: 2.5%;
        ;
    }


}

@media (max-width: 835px) {

    .firstTwo {
        display: block;
    }

    .hp3-card {
        margin-top: 1%;
    }

    .hp3-card-heading {
        font-size: 1.4rem;
        margin-top: 2.6%;
    }

    .hp3-card-content {
        font-size: 1.2rem;
    }

    .hp-projects {
        height: 23rem;
    }

}

@media (max-width: 883px) {
    .HomePage2 {
        display: block;
        margin-bottom: 20rem;
        margin-top: 7rem;
    }
}

@media (max-width: 763px) {
    .mrgn-nav {
        margin-left: 2%;
    }
}

@media (max-width: 744px) {

    .contact-text-heading {
        font-size: 1.7rem;
    }

    .contact-text-content-1-content {
        font-size: 1rem;
    }

    .mail-icon {
        font-size: 1.2rem;
    }

    .social-icons a svg {
        font-size: 1.5rem;
    }

}

@media (max-width: 704px) {

    .nav-btn {
        padding: 0.3rem 1rem 0.3rem 1rem;

    }

    .nav-btn-link {
        font-size: 0.9rem;
    }

    .small-lnk {
        font-size: 1.8rem;

    }

    .ashish-txt {
        font-size: 3.5rem;
    }
}


@media (max-width: 690px) {

    .hp4 {
        display: block;
    }

    .hp4Form {
        margin-left: 5%;
        margin-top: 9%;
        max-width: 80%;
        max-height: 20rem;
        position: relative;
    }

    .contact-text-heading {
        font-size: 2.5rem;
    }

    .contact-text-content-1-content {
        font-size: 1.8rem;
    }

    .mail-icon {
        font-size: 1.8rem;
    }

    .social-icons a svg {
        font-size: 2rem;
    }

    .social-icons {
        margin-left: 0rem;
    }





    .form-heading {
        margin-top: 4%;
        margin-left: 14%;
        font-size: 2.3rem
    }

    .transparent-input {
        font-size: 1.3rem;
    }

    .transparent-input-textarea {
        font-size: 1.3rem;
    }

    .form-button-sbmt {
        margin-top: 1rem;

    }

}


@media (max-width: 662px) {

    .nav-btn {
        /* padding: 0rem 1rem 0rem 1rem; */
        margin: 0.6rem 0.8rem
    }

    .nav-btn-link {
        font-size: 0.7rem;
    }

    .small-lnk {
        font-size: 1.4rem;

    }

    .bg-hover-grey svg {
        font-size: 1rem;
    }

    /* .ashish-txt{
        font-size: 3.5rem;
    } */

    .hp2-card1 {
        margin-top: 11%;
        margin-left: 4%;
        margin-right: 4%;
    }

    .hp2-card2 {
        margin-top: 7%;
        margin-left: 3%;
        margin-right: 3%;
    }

    .marign-adjust {
        margin-top: 23%;
    }
}

@media (max-width: 636px) {
    .hp-text-text {
        text-align: center;
        font-size: 1.2rem;
    }
}

@media (max-width: 603px) {

    .hp-projects {
        height: 28rem;
    }
}

@media (max-width: 590px) {

    .small-lnk {
        font-size: 1.3rem;

    }
}

@media (max-width: 590px) {

    /* .nav-btn {
        padding: 0.3rem 1rem 0.3rem 1rem;

    }
    .nav-btn-link{
        font-size: 0.9rem;
    } */
    .ashish-txt {
        font-size: 2.5rem;
    }
}

@media (max-width: 563px) {

    .homeSection2 {
        margin-left: 0rem;
    }

    .homes-div-res {
        display: flex;
        font-size: 1.2em;
        font-family: monospace;
        /* overflow: hidden; */
        /* border: 1px solid; */
        resize: horizontal;
        justify-content: center;
        margin-bottom: 7%;
    }

    .nav2 {
        min-height: 1.2rem;
        display: block;
        white-space: wrap;
    }

    .small-lnk {
        font-size: 1.1rem;

    }


}

@media (max-width: 519px) {
    .homepage3 {
        display: block;
        margin-right: 2%;
    }


}


@media (max-width: 690px) {
    .contact-text-content-1-content {
        font-size: 1.3rem;
    }
}



.mobile-only {
    display: none;

}


@media (max-width: 537px) {

    .homepage-main {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .hp-left {
        position: relative;

        margin-bottom: 2rem;

        /* background: #F48024; */
        /* width:576px; */
        /* height: 324px; */
    }

    .hp-right {
        position: relative;
        /* background: #EFF0F1; */
        /* width:576px; */
        /* height: 324px; */
    }

    .homepage-main {
        /* justify-content: space-between; */
        /* display: block; */
    }

    .hp-left {
        order: 2;
    }

    .hp-right {
        order: 1;
        /* padding-left: 4rem; */
    }

    .hide-mobile {
        display: flex;
        margin-left: -0.5rem;
    }

    .nav-btn-2 {
        display: none;
    }


    .mobile-only {
        /* display: contents; */
        font-size: 1rem;
        margin: 1rem;
    }

    .homepage-main {
        background-image: linear-gradient(#dbe600, rgb(183, 0, 255));
        /* background-image: linear-gradient(#e15757, yellow); */
    }

    .ashish-txt {
        padding-left: 0rem;
    }

}




@media (max-width: 519px) {
    .nav-btn-link3 {
        font-size: 0.8rem;
    }

    .nav-btn3 {
        border: 1px solid #debbbb;
        padding: 0rem 0.3rem 0rem 0.3rem
    }

    .homepage3 {
        margin-left: 4%;
        margin-right: 5%;
    }
}

@media (max-width: 519px) {

    .homepage3-heading {
        margin-top: 11%;
        margin-left: 6.3%;
    }

    .nav-icon-add {

        margin-right: 0.5rem;

    }

    .mrgn-nav {
        margin-left: 9%;
    }
}

@media (max-width: 478px) {

    .hp-projects {
        height: 34rem;
    }

}

@media (max-width: 455px) {


    .card-skill {
        margin-left: 0rem;
    }

    .mrgn-nav {
        margin-left: 3%;
    }
}

@media (max-width: 422px) {


    .mrgn-nav {
        margin-left: 0%;
    }

    .nav-icon-add {

        margin-right: 0rem;

    }
}


@media (max-width: 883px) {
    .hp2-card1-img {
        width: 92%;
        height: 100%;
    }
}

@media (max-width: 455px) {


    .contact-text {
        margin-top: 8%;
        margin-left: 1%;

    }
}

@media (max-width: 400px) {
    .hp-projects {
        height: 25rem;
    }

    .hp3-card-content {
        font-size: 0.9rem;
    }

    .hp3-card-heading {
        font-size: 1.2rem;
        /* margin-top: 2.6%; */
    }

    .card-btn-skill {
        padding: 0rem 0.2rem 0rem 0.2rem;
    }

    .card-btn-skill-txt {
        font-size: 0.45rem;
    }

    .hp3-card-link {
        margin-top: 6%;
        font-size: 1rem;
    }
}

@media (max-width: 386px) {
    .nav-btn-link3 {
        font-size: 0.6rem;
    }

    .nav-btn-link {
        font-size: 0.6rem;
    }

    .bg-hover-grey svg {
        font-size: 0.8rem;
    }

    .small-lnk {
        font-size: 0.8rem !important;

    }

    .make-it-up {
        margin-top: -1rem;
    }

    .hp2-card2 {
        height: 26rem;
    }
}

@media (max-width: 356px) {
    .nav-btn {
        /* padding: 0rem 1rem 0rem 1rem; */
        margin: 1rem 0.3rem;
    }
}

@media (max-width: 335px) {
    .hp-projects {
        height: 30rem;
    }
}

@media (max-width: 325px) {
    .bg-hover-grey svg {
        font-size: 0.5rem;
    }

    .nav-btn-link {
        font-size: 0.5rem;
    }

}

@media (max-width: 420px) {


    .homepage-main {
        margin-left: 0%;
        margin-right: 0%;
    }

    .glow-on-hover {
        width: 167px;
        height: 44px;
    }

    .ashish-txt {
        font-size: 2.2rem;
    }

    .hp-left {
        /* position: absolute; */
        left: -6px;
    }

    .nav-btn-link {
        margin-left: 0.3rem;
    }

    .nav-btn {
        padding: 0.3rem 0.7rem 0.3rem 0.6rem;
    }

    .nav {
        justify-content: center;
        display: flex;
    }

    .tp {
        justify-content: flex-start;
    }

    .hp-text-text {
        text-align: center;
        font-size: 1rem;
    }

    .card1-text-heading {
        font-size: 1rem;
        font-family: 'Titillium Web', sans-serif;
        font-weight: 800;
        margin-bottom: 1rem;
    }

    .card1-text-content {
        font-size: 0.7rem;
        color: #cfcfcf;

    }


    .nav-btn-link6 {
        font-size: 0.9rem;
    }

    .nav-btn6 {
        margin: 0.2rem 0.2rem;
        padding: 0rem 4rem 0.1rem 2rem;
        fill: rgb(0, 0, 0);
        border-radius: 7rem;
        border: 2px solid #debbbb;
        background-color: #ffffff;
        /* cursor: default; */
        display: inline;
        /* border-style: double; */
        justify-content: flex-start;
        display: flex;
        font-size: 0.7rem;
    }

    .hp2-card1-img {
        width: 92%;
        height: 100%;
    }

    .card2-text-heading p {
        font-size: 1rem;
    }

    .card2-text-content {
        font-size: 0.8rem;
        color: #4b4b4b;
    }

    .hp2-card2 {
        height: 21rem;
    }




    .hp3-card-heading {
        font-size: 1.1rem;
        margin-top: 0.5rem;
        /* margin-top: 2.6%; */
    }

    .hp3-card-content {
        font-size: 0.8rem;
        color: #4b4b4b;
        text-align: justify;
    }

    .hp3-card-link a {
        font-size: 1rem;
        color: #6205b4;
    }

    .hp-projects {
        height: 24rem;
    }

    .hp-projects {
        height: 21rem;
        margin-top: 2.5rem;
    }

    .hp3-card {
        margin: 1.7rem 0rem;
        padding: 1.2rem 1rem 0rem 1rem
    }

    .homepage3-heading {

        font-size: 2rem;

    }


    .hp4 {
        margin-left: 0%;
    }

    .hp4Form {
        margin-left: 1%;
        margin-top: 9%;
        max-width: 106%;
        max-height: 28rem;
        position: relative;
    }

    .form-heading {
        margin-top: 3.5rem;
        font-size: 1.3rem;
    }

    .transparent-input {
        font-size: 1rem;
    }

    .form-button-sbmt {
        font-size: 1rem;
    }


    .hp4Form {
        height: calc(80% + -53px);
    }


    .hp4Form::before {
        width: 15px;
        height: calc(27% + 12px);
    }

    .hp4Form::after {
        bottom: 75px;
        right: -4px;
        width: 13px;
        height: calc(35% + 15px);
        transform: skewY(-45deg);
    }



    .contact-text {
        margin-top: 8%;
        margin-left: 3%;
        font-size: 1rem;
        font-family: 'Titillium Web', sans-serif;
        font-weight: 800;
        /* margin-bottom: 1rem; */
        color: #7ff2b9;
    }

    .contact-text-heading {
        font-size: 1.5rem;
        color: white;
    }

    .contact-text-content-1-content {
        font-size: 1rem;
        margin-left: 7.1px;
    }

    .contact-text-content-1 {
        /* justify-content: center; */
        display: flex;
        flex-direction: row;
        align-items: flex-end;

    }

    .contact-text-content {
        margin-top: 10%;
        margin-left: 1rem;
    }

    .social-icons {
        margin-top: 15%;
        font-size: 2rem;
        margin-bottom: 5rem;
        font-family: 'Titillium Web', sans-serif;
        font-weight: 800;
        /* margin-bottom: 1rem; */
        color: #ff0000;
        text-decoration: none;
        padding: 0rem;
    }

    .social-icons a {
        padding: 1rem;
        font-size: 0.5rem;
    }

}

@media (max-width: 314px) {
    .hp-projects {
        height: 24rem;
        margin-top: 2.5rem;
    }
}