* {
    /* background-color: black; */
    scroll-behavior: smooth;
    /* background-color: rgb(0, 0, 0); */
    background-color: rgb(0, 0, 0);
    /* scroll-padding-top: 80px; */
  
  
  }
  

  
  html::-webkit-scrollbar {
    width: 0.6rem;
    position: absolute;
  
    overflow: auto;
  }
  
  html::-webkit-scrollbar-track {
    position: absolute;
  
    background: rgb(2, 2, 2);
    border-radius: 10px;
  }
  
  html::-webkit-scrollbar-thumb {
    background: rgb(136, 136, 136);
    border-radius: 10px;
  }
  
  html::-webkit-scrollbar-thumb:hover {
    background: rgb(100, 100, 100);
    border-radius: 10px;
  }
  
  html::-webkit-scrollbar-thumb:active {
    background: rgb(68, 68, 68);
    border-radius: 10px;
  }